import React from "react";

const IcariusLogo = ({ useLoginVersion, height, mainColor, theme }) => {

  const secondaryColor = theme === "dark" ? "#fff" : "#000";
  const logoHeight = height || "180px";

  if (useLoginVersion) {
    return (
      <svg className="peopleGateGo-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 222.61" width="400" height={logoHeight}>
        <g fill='none' transform="scale(0.15) translate(1050 0)">
          <ellipse stroke={mainColor || secondaryColor} strokeWidth='43px' strokeMiterlimit={10} cx="564.15" cy="425.14" rx="542.65" ry="403.64" />
          <circle stroke={mainColor || secondaryColor} strokeWidth='43px' strokeMiterlimit={10} cx="564.15" cy="425.14" r="372.89" />
          <ellipse stroke={mainColor || secondaryColor} strokeWidth='43px' strokeMiterlimit={10} cx="564.15" cy="425.14" rx="182.52" ry="370.27" />
        </g>
        <path fill={secondaryColor} d="M16.15,220.67H0V152.75H16.15Z" />
        <path fill={secondaryColor} d="M76.28,215.33a46.36,46.36,0,0,0,14.53-2.28,41.76,41.76,0,0,0,13.31-7.42l5.57,5.82a48.43,48.43,0,0,1-15.37,8.15,60.29,60.29,0,0,1-19.15,3,70.87,70.87,0,0,1-18.88-2.33,41.52,41.52,0,0,1-14.36-6.89,30.81,30.81,0,0,1-9.19-11.25,35,35,0,0,1-3.23-15.43,35.67,35.67,0,0,1,3.17-15.42,30.92,30.92,0,0,1,9-11.26,40.58,40.58,0,0,1,14.09-6.89,67.12,67.12,0,0,1,18.26-2.33,60.35,60.35,0,0,1,19.16,3A48.57,48.57,0,0,1,108.57,162L103,167.79a41.76,41.76,0,0,0-13.31-7.42,46.4,46.4,0,0,0-14.53-2.28,36.31,36.31,0,0,0-11.25,1.7,23.26,23.26,0,0,0-15,14.07,39.16,39.16,0,0,0,0,25.71,23.09,23.09,0,0,0,6.13,8.87,25.36,25.36,0,0,0,9.35,5.19A39.11,39.11,0,0,0,76.28,215.33Z" />
        <path fill={secondaryColor} d="M170.38,152.75l31.74,67.92h-16l-7.35-16.5H136.41l-7.35,16.5H113l31.74-67.92ZM140.31,196.9h34.52L158.13,161H157Z" />
        <path fill={secondaryColor} d="M251.11,152.75a63.81,63.81,0,0,1,15,1.61A34.29,34.29,0,0,1,277.28,159a21.51,21.51,0,0,1,7,7.47,20.39,20.39,0,0,1,2.45,10,19.74,19.74,0,0,1-4.46,13.1q-4.46,5.34-12.8,8.15l18.37,22.9H269.49l-16.37-20.38h-26v20.38H211V152.75ZM227.17,160v33h23.94q8.81,0,13.59-4t4.79-12.47q0-8.43-4.79-12.46t-13.59-4Z" />
        <path fill={secondaryColor} d="M316.26,220.67H300.11V152.75h16.15Z" />
        <path fill={secondaryColor} d="M371.94,215.82a34.59,34.59,0,0,0,9.85-1.31,19.62,19.62,0,0,0,7.52-4.08,18.34,18.34,0,0,0,4.84-7.08A27.81,27.81,0,0,0,395.88,193V152.75H412V193q0,14.94-10.47,22.26t-29.62,7.33q-19.28,0-29.68-7.33T331.85,193V152.75H348V193a27.82,27.82,0,0,0,1.72,10.33,18.36,18.36,0,0,0,4.85,7.08,19.5,19.5,0,0,0,7.51,4.08A34.61,34.61,0,0,0,371.94,215.82Z" />
        <path fill={secondaryColor} d="M500,202.72a16,16,0,0,1-2,7.81,17.09,17.09,0,0,1-6.52,6.31,39.55,39.55,0,0,1-11.69,4.22,85.5,85.5,0,0,1-17.65,1.55,83.83,83.83,0,0,1-11.53-.78,77.09,77.09,0,0,1-10.69-2.23,57.47,57.47,0,0,1-9.35-3.54,38.62,38.62,0,0,1-7.41-4.61l5.57-5.82a46,46,0,0,0,14.81,7.23,66.07,66.07,0,0,0,18.6,2.47q11.25,0,15.92-2.71c3.12-1.81,4.68-4.31,4.68-7.47q0-3.69-3.68-6.21c-2.45-1.69-6.6-3.33-12.47-4.95l-21.16-5.82q-10.47-2.91-15.81-7.43a14.37,14.37,0,0,1-5.34-11.49,13.6,13.6,0,0,1,1.94-6.94,17.68,17.68,0,0,1,6.07-5.92,35.12,35.12,0,0,1,10.53-4.07,68.43,68.43,0,0,1,15.42-1.51,75.87,75.87,0,0,1,10.86.78,74.35,74.35,0,0,1,10.35,2.23,59.2,59.2,0,0,1,9.25,3.54,38.84,38.84,0,0,1,7.4,4.61l-5.57,5.82a47,47,0,0,0-14.25-7.23,60.72,60.72,0,0,0-18-2.47q-8.36,0-12.53,2.52c-2.78,1.69-4.17,3.75-4.17,6.21a7.58,7.58,0,0,0,.78,3.54,8.15,8.15,0,0,0,2.61,2.82,21,21,0,0,0,4.85,2.42q3,1.13,7.46,2.38l21,5.82q10.14,2.82,15.93,7.37A14.12,14.12,0,0,1,500,202.72Z" />
      </svg>
    );
  }

  return (
    <svg className="peopleGateGo-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1900 266.84" width="400" height={logoHeight}>
      <g fill='none' transform="scale(0.3)">
        <ellipse stroke={mainColor || secondaryColor} strokeWidth='43px' strokeMiterlimit={10} cx="564.15" cy="425.14" rx="542.65" ry="403.64" />
        <circle stroke={mainColor || secondaryColor} strokeWidth='43px' strokeMiterlimit={10} cx="564.15" cy="425.14" r="372.89" />
        <ellipse stroke={mainColor || secondaryColor} strokeWidth='43px' strokeMiterlimit={10} cx="564.15" cy="425.14" rx="182.52" ry="370.27" />
      </g>
      <path fill={secondaryColor} d="M449.62,235.21h-48.4V31.63h48.4Z" />
      <path fill={secondaryColor} d="M629.87,219.21a139,139,0,0,0,43.57-6.83q20.85-6.82,39.89-22.25L730,207.58Q711,223,684,232a180.34,180.34,0,0,1-57.41,9q-31.38,0-56.58-7T526.89,213.4a92.74,92.74,0,0,1-27.54-33.74q-9.68-20.07-9.68-46.24t9.52-46.24a92.41,92.41,0,0,1,27-33.74q17.52-13.66,42.22-20.64t54.75-7a180.29,180.29,0,0,1,57.41,9q27,9,46.07,24.43L710,76.71A125.66,125.66,0,0,0,670.1,54.46a139.22,139.22,0,0,0-43.56-6.83,108.75,108.75,0,0,0-33.72,5.09,69.73,69.73,0,0,0-44.9,42.17q-6.51,16.14-6.51,38.53T547.92,172a69.46,69.46,0,0,0,18.36,26.61,76.31,76.31,0,0,0,28,15.56A117.86,117.86,0,0,0,629.87,219.21Z" />
      <path fill={secondaryColor} d="M911.94,31.63l95.14,203.58H959l-22-49.44H810.13l-22,49.44H740L835.16,31.63ZM821.81,164H925.29L875.22,56.35h-3.34Z" />
      <path fill={secondaryColor} d="M1154,31.63q25,0,44.9,4.8t33.54,14a64.49,64.49,0,0,1,21,22.39q7.35,13.25,7.35,30.1,0,23.28-13.35,39.27T1209,166.57l55.08,68.64H1209L1160,174.14h-77.78v61.07h-48.4V31.63Zm-71.77,21.81v98.88H1154q26.37,0,40.72-12.07T1209,102.88q0-25.29-14.36-37.37T1154,53.44Z" />
      <path fill={secondaryColor} d="M1349.22,235.21h-48.4V31.63h48.4Z" />
      <path fill={secondaryColor} d="M1516.12,220.67a103.36,103.36,0,0,0,29.55-3.93,58.74,58.74,0,0,0,22.53-12.21,55.23,55.23,0,0,0,14.52-21.23q5.17-12.95,5.17-31V31.63h48.41V152.32q0,44.79-31.38,66.75t-88.8,22q-57.75,0-89-22T1396,152.32V31.63h48.41V152.32q0,18,5.17,31a55.23,55.23,0,0,0,14.52,21.23,58.74,58.74,0,0,0,22.53,12.21A103.29,103.29,0,0,0,1516.12,220.67Z" />
      <path fill={secondaryColor} d="M1900,181.41a47.72,47.72,0,0,1-6,23.41q-6,10.91-19.53,18.9t-35,12.65q-21.53,4.65-52.9,4.66A252.48,252.48,0,0,1,1752,238.7a230.68,230.68,0,0,1-32-6.69,172.07,172.07,0,0,1-28-10.62,115,115,0,0,1-22.2-13.81l16.69-17.45q19,14.25,44.4,21.67t55.75,7.41q33.71,0,47.73-8.14t14-22.39q0-11.06-11-18.62t-37.38-14.83l-63.43-17.45q-31.38-8.72-47.4-22.25t-16-34.46a40.83,40.83,0,0,1,5.84-20.79q5.84-10,18.19-17.74t31.55-12.22q19.19-4.5,46.23-4.5a228.83,228.83,0,0,1,32.55,2.32,223.22,223.22,0,0,1,31,6.69,177.87,177.87,0,0,1,27.71,10.62,116.16,116.16,0,0,1,22.2,13.81l-16.69,17.45A141,141,0,0,0,1828.9,55q-23.72-7.41-54.08-7.41-25,0-37.55,7.56T1724.75,73.8a22.78,22.78,0,0,0,2.34,10.62,24.51,24.51,0,0,0,7.84,8.43,62.72,62.72,0,0,0,14.52,7.27q9,3.34,22.37,7.13l63.09,17.45q30.38,8.43,47.73,22.1T1900,181.41Z" />
    </svg>
  );
};
export default IcariusLogo;
